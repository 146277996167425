<template>
  <div :style="$vuetify.breakpoint.smAndDown ? 'padding: 15% 5%' : 'padding: 6% 5%'">
    <PageBreadcrumbs :data="breadcrumbs" />
    <vs-row justify="center">
      <h1>Designer Products</h1>
      <span class="material-symbols-outlined"> female </span>
    </vs-row>
    <Products :products="allProducts" />
  </div>
</template>

<script>
export default {
  name: 'AllProducts',
  components: {
    PageBreadcrumbs: () => import('../components/PageBreadcrumbs.vue'),
    Products: () => import('../components/Products.vue'),
  },
  data: () => ({
    breadcrumbs: [
      {
        text: 'Home',
        disabled: false,
        href: '/',
      },
      {
        text: 'All Products',
        disabled: true,
      },
    ],
  }),
  computed: {
    allProducts() {
      return this.$store.state.products;
    },
  },
};
</script>